<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder h2back m-0">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span>
        {{ $t('Ficha de producto') }}
      </h2>
    </div>
    <b-row>
      <b-col cols="12">
        <b-card class="height-card">
          <b-row>
            <b-col v-if="editLinkVisible" cols="12" class="text-right">
              <b-link :to="{ name: 'editProduct', params: { id: this.$route.params.id } }" class="btn-primary">
                <span class=""><feather-icon icon="Edit2Icon" /> {{ $t('Editar') }}</span>
              </b-link>
              <hr />
            </b-col>
          </b-row>
          <b-row class="position-relative">
            <b-col lg="10" order="2" order-lg="1">
              <p v-if="product.category" class="mb-50 text-uppercase p_subtitle">
                {{ product.category.name }}
              </p>
              <div class="d-flex align-items-center p_title">
                <p class="">
                  {{ product.name }}
                </p>
              </div>
            </b-col>
            <b-col lg="2" class="text-right pt-0 p-md-3" order="1" order-lg="2">
              <a v-if="product.url_web" :href="product.url_web" class="btn btn-primary mb-2" target="_blank">
                {{ $t('Ver en la web') }}
              </a>
            </b-col>
            <!-- datos -->
            <b-col cols="12" class="mt-2" order="3">
                <hr>
                <b-row>
                  <b-col lg="5" md="12" class="">
                    <div v-if="product.ref" class="p_data mb-2">
                      <span class="p_data_text mb-1 d-block">
                        <span v-html="product.modelo"></span>
                      </span>
                    </div>
                    <div v-if="product.description" class="p_data mb-2">
                      <strong class="p_data_title mb-1 d-block">{{ $t('Descripcion') }}:</strong>
                      <div class="p_data_text" v-html="product.description" />
                    </div>
                    <div v-if="product.objetives" class="p_data mb-2">
                      <strong class="p_data_title mb-1 d-block">{{ $t('goals') }}:</strong>
                      <div class="p_data_open" v-html="product.objetives" />
                    </div>
                    <div v-if="product.characteristics" class="p_data mb-2">
                      <strong class="p_data_title mb-1 d-block">{{ $t('orders.characteristics') }}:</strong>
                      <div class="p_data_open" v-html="product.characteristics" />
                    </div>
                    <div v-if="product.price && role === 'super_admin'" class="p_data mb-2">
                      <strong class="p_data_title mb-1 d-block">{{ $t('Precio') }}:</strong>
                      <div v-if="product.tipe_price && product.tipe_price !== 'fijo'" class="p_data_text" v-html="product.price + '€ / mm'" />
                      <div v-else class="p_data_text" v-html="product.price + '€'" />
                    </div>
                  </b-col>
                  <b-col lg="6" md="12" class="text-center">
                    <div v-for="(item, index) in product.image" :key="index" class="mb-2">
                      <img :src="item.path" :alt="index" class="w-75">
                    </div>
                  </b-col>
                </b-row>
                <div class="d-flex pt-3" v-if="product.documents">
                  <div v-for="(item, index) in product.documents" :key="index" class="mr-2">
                    <a :href="item.path" target="_blank" class="btn btn-primary">{{ item.name }}</a>
                  </div>
                </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-tabs v-if="product && product.gestor_documental && product.gestor_documental.length > 0">
      <b-tab>
        <template #title>
          <feather-icon icon="ClipboardIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">{{ $t('Documentos') }}</span>
        </template>
        <b-card no-body>
          <DocumentsTable :parameters="documentsTableParameters" :view="'products'" />
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DocumentsTable from '@/components/documents/table/DocumentsTable.vue'

export default {
  components: { DocumentsTable },
  computed: {
    ...mapGetters({
      role: "auth/getRole",
      product: 'products/getProduct',
      currentUser: 'auth/getUser',
    }),
    documentsTableParameters() {
      return { product_id: this.$route.params?.id || null }
    },
    editLinkVisible() {
      return this.currentUser.roles[0].name === 'super_admin'
    },
  },
  mounted() {
    this.getProduct(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      getProduct: 'products/getProduct',
    }),
  },
}
</script>

<style scoped></style>
